import React, {Component} from "react"
import {wainwrightUnitStructure} from "../data/wainwrightUnitStructure"
import UnpackUnitChildren from '../functions/unpackUnitChildren'
import {UnitData} from "../data/unitData"
import {Link} from "gatsby"
import ShopifyEmbed from '../components/ShopifyEmbed'
import HeaderFooter from '../components/headerFooter'
import Guarantee from '../components/guarantee'
import {GetCurrentWebsite} from '../functions/getCurrentWebsite';




class Stores extends React.Component {
	constructor(props) {
	    super(props);   
	    
    	let currentWebsite = GetCurrentWebsite(); // Function to check domain and return templated info based on which domain a user has visited. (CURRENTLY IS HARD CODED)


	    let theCollectionID, unitInfo, companyInfo, unitID, base_gear, topLevelUnitInfo, currentWindowLocation, currentWindowPathnameSplit2, collectionID, unitOrCivilian;
		
		try{
			if(!UnitData[this.props.params['*'].split('/')[0]]){ // if on default /stores
				unitInfo = UnitData[currentWebsite.top_level_unit_id];				// show default store
				companyInfo = unitInfo;
				unitID = currentWebsite.top_level_unit_id;
			} else{
				unitID = this.props.params['*'].split('/').slice(-1).pop();
				unitInfo = UnitData[this.props.params['*'].split("/").slice(-1)];
				companyInfo = UnitData[this.props.params['*'].split("/")[0]]		
			} 
		} catch (exception) {
			unitInfo = UnitData[currentWebsite.top_level_unit_id];				// show default store
			companyInfo = unitInfo;
		}

		if (currentWebsite.top_level_unit_id){
			topLevelUnitInfo = UnitData[currentWebsite.top_level_unit_id]
		}

		if (currentWebsite.base_gear){
			base_gear = UnitData[currentWebsite.base_gear]
		}

		try{
			currentWindowLocation = window.location.pathname;
			currentWindowPathnameSplit2 = currentWindowLocation.split('/')[2];
		} catch(e){
			currentWindowLocation = "";
			currentWindowPathnameSplit2 = null;
		}

		if (unitInfo.shopifyUnitCollectionId){
			collectionID = parseInt(unitInfo.shopifyUnitCollectionId);
			unitOrCivilian = "unit";

		} else {
			collectionID = parseInt(unitInfo.shopifyCivilianCollectionId);
			unitOrCivilian = "civilian";
		}

		this.state = {
			topLevelUnitInfo: topLevelUnitInfo,
			unitID: unitID,
			unitInfo: unitInfo,
			companyInfo: companyInfo,
	    	collectionID: collectionID,
	    	unitOrCivilian: unitOrCivilian,
	    	hideUnitLinks: false,
	    	baseGear: base_gear,
	    	currentWindowPathname: currentWindowLocation,
	    	currentWindowPathnameSplit2: currentWindowPathnameSplit2
	    };
	    
	  }

	componentDidMount(){}

	componentDidUpdate(){
		let unitInfo;
		let companyInfo;
		let unitID;
		let base_gear;
		let currentWindowLocation;
		let currentWindowPathnameSplit2;


    	let currentWebsite = GetCurrentWebsite(); // Function to check domain and return templated info based on which domain a user has visited. (CURRENTLY IS HARD CODED)
		

		try{
			currentWindowLocation = window.location.pathname.split('/')[2]
		} catch(e){
			currentWindowLocation = null;
		}

		try{
			if(!UnitData[window.location.pathname.split('/')[2]] && window.location.pathname.split('/')[2] !== "base_gear"){ // if on default /stores
				unitInfo = UnitData[currentWebsite.top_level_unit_id];				// show default store
				companyInfo = unitInfo;
				unitID = currentWebsite.top_level_unit_id;
				console.log("Should be showing top level unit gear")
			} else if(window.location.pathname.split('/')[2] === "base_gear"){
				unitID = currentWebsite.base_gear;
				unitInfo = UnitData[unitID];
				companyInfo = unitInfo;
			}else{
				unitID = window.location.pathname.split('/').slice(-1).pop();
				unitInfo = UnitData[unitID]; // info for the current Unit
				companyInfo = UnitData[window.location.pathname.split("/")[2]]	  // info for the parent company
			} 
		} catch (exception) {
			unitInfo = UnitData[currentWebsite.top_level_unit_id];				// show default store
			companyInfo = unitInfo;
			console.log("threw exception", exception)
		}

		try{
			currentWindowLocation = window.location.pathname;
			currentWindowPathnameSplit2 = currentWindowLocation.split('/')[2];
		} catch(e){
			currentWindowLocation = "";
			currentWindowPathnameSplit2 = null;
		}


		const theCollectionID = parseInt(unitInfo.shopifyUnitCollectionId);
	

		if(unitInfo !== this.state.unitInfo || companyInfo !== this.state.companyInfo) {
	
			this.setState({
				unitID: unitID,
				unitInfo: unitInfo,
				companyInfo: companyInfo,
				collectionID: theCollectionID,
				unitOrCivilian: "unit",
				currentWindowPathname: currentWindowLocation,
				currentWindowPathnameSplit2: currentWindowPathnameSplit2

		    });
		}
	}




	render(){

	   let numberOfChildren = (this.state.topLevelUnitInfo.unitChildren || []).length;

	   let props = this.props; // this is a ghetto fix to avoid replacing every instance of 'props' below as I work to move from a function to a class component


		return(

			
				<>
					<HeaderFooter>


					<div id="store-link-container">
						<div className="grid-container">
					      	<div className="grid-x show-for-small-only" style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', paddingTop: '10px'}}>
								<button id="unit-link-show-hide-button" className="button cell small-12" style={{padding: "10px"}} onClick={() => this.setState({hideUnitLinks: !this.state.hideUnitLinks})}>
									{this.state.hideUnitLinks ? "Show" : "Hide"} Unit links
								</button>
					      	</div>

					      	{!this.state.hideUnitLinks &&
							<div id="store-links" className="">
					          	<div id="top-level-store-links" className="grid-x grid-margin-x">
							      	<div className="cell small-6 link-container main-store">
							      		<Link id="top-level-unit" 
							      			className={this.state.currentWindowPathnameSplit2 === "" 
							      						? "selected link-label"
							      						: this.state.currentWindowPathnameSplit2 === "base_gear" 
							      							? "link-label" 
							      							: "child-selected link-label"
							      			} 
							      			to={"/stores/"}>
							      				{this.state.topLevelUnitInfo.shortName}{this.state.topLevelUnitInfo.motto ? (' "'+this.state.topLevelUnitInfo.motto)+'"' : ""}
							      		</Link>
							      	</div>
							      	{this.state.baseGear &&
							      	<div className="cell small-6 link-container main-store">
							      		<Link id="base_gear-link" className={this.state.currentWindowPathnameSplit2 === "base_gear" ? "selected link-label": "link-label"} to={"/stores/base_gear"}>{this.state.baseGear.name}</Link>
							      	</div>
							        }

						      	</div>

					      		{(this.state.currentWindowPathnameSplit2 !== "base_gear") &&
						    		<div id="mid-level-store-links" style={{ paddingTop: "10px", paddingBottom: "10px", borderTop: "1px solid #eee"}} className={"grid-x grid-margin-x small-up-3" + " medium-up-"+numberOfChildren+" large-up-"+numberOfChildren}>

									    {(this.state.topLevelUnitInfo.unitChildren || []).map((unitChild, index) => {

									        return (<UnpackUnitChildren index={index} key={unitChild} unitChild={unitChild} inheritProps={props} />)
									      })
									    }


							      	</div>
							     }
							    {this.state.companyInfo.unitChildren &&
							    	<>
							      	{this.state.companyInfo.unitChildren.length > 0 && this.state.companyInfo !== this.state.topLevelUnitInfo
							      		? (
							      			<div style={{borderTop: "1px solid #eee", borderBottom: "1px solid #eee"}} className={"grid-x grid-margin-x small-up-"+this.state.companyInfo.unitChildren.length+ " medium-up-"+this.state.companyInfo.unitChildren.length+" large-up-"+this.state.companyInfo.unitChildren.length}>
										    	{(this.state.companyInfo.unitChildren) ? subLinks(this.state.companyInfo, props) : null}
										    </div>
					

							      			)
							      		: null
							      	}
							      	</>
							    }
					    	</div>
							}
						</div>
					</div>



					<div className="grid-container">
					    	{(this.state.unitInfo.storeNeeded) 
					    		? noGear(this.state.companyInfo)
					    		: <div style={{marginBottom: "30px", marginTop: "30px"}} className="grid-x grid-margin-x text-center" >
					    				<Guarantee />
										
										<h1 className="cell small-12" id="store-name">{this.state.unitInfo.shortName}{this.state.unitOrCivilian === "civilian" ? " civilian " : " Unit "} gear store</h1>
										{this.state.companyInfo.shopifyCivilianCollectionId && this.state.companyInfo.shopifyUnitCollectionId &&
											<>
												<button className={this.state.unitOrCivilian === "civilian" ? "civilian-or-unit-store-label link-label small-6 cell selected" : "civilian-or-unit-store-label link-label small-6 cell"} href="#" onClick= {() => this.setState({collectionID: parseInt(this.state.unitInfo.shopifyCivilianCollectionId), unitOrCivilian: "civilian"})}>{this.state.unitOrCivilian === "civilian" ? "Civilian (family/off-duty) gear" : "See Civilian (family/off-duty) gear"}</button>
						          				<button className={this.state.unitOrCivilian === "unit" ? "civilian-or-unit-store-label link-label small-6 cell selected" : "civilian-or-unit-store-label link-label small-6 cell"} href="#" onClick= {() => this.setState({collectionID: parseInt(this.state.unitInfo.shopifyUnitCollectionId), unitOrCivilian: "unit"})}>{this.state.unitOrCivilian === "unit" ? "Unit gear" : "See Unit gear"}</button>
					          				</>
				          				}
									</div>
					    	}


					    
					    <div id="products-main-container" className="grid-x grid-margin-x">

					    	<ShopifyEmbed hideOthers unitInfo={this.state.unitInfo} collectionID={parseInt(this.state.collectionID)} />
					    	
					    </div>
						

					</div> {/* end grid-container */}

				    
			        </HeaderFooter>

				</>
			)	
		
	}
}

			

const TheStores = (mainProps) => {

	
}

export default Stores;


const subLinks = (unitInfo, props) => {
	const subLinkChildren = ( unitInfo.unitChildren || []).map((unitChild, index) => {
		let unitIdFromurl;
		try { 
			unitIdFromurl = props.params['*'].split('/').slice(-1).pop()
		} catch (error){
			unitIdFromurl = "";
		}
		return(
		    	<div className={"link-container cell"} key={unitChild} >
			      <Link id={unitChild + "-link"} className={(unitIdFromurl === unitChild) ? "selected link-label": "link-label"} to={"/stores/"+unitInfo.id+"/"+unitChild}>{UnitData[unitChild.toString()].shortName}{UnitData[unitChild].motto ? (' "'+UnitData[unitChild].motto)+'"' : ""}</Link>
			    </div>
			)
        
      })

	return (subLinkChildren);
    
}



const noGear = (unitInfo) => {
	return(
		<div className="grid-x grid-margin-x text-center" style={{minHeight: "30vh", justifyContent: "center", alignItems: "center"}} >
			<div className="cell small-12">
				<p>Looks like {unitInfo.name ? unitInfo.name : "your unit"} doesn't have Unit specific gear yet</p><Link to="/intake"><button className="button">Create a store</button></Link>
			</div>
		</div>
	)
}








