import React, {Component} from "react";





class shopifyEmbed extends React.Component {
  constructor(props) {
      super(props);   
      
      this.state = {
        collectionID: this.props.collectionID,
        loaded: false
      };

  }

  componentDidMount(){

  }

  componentDidUpdate(){
  }


  render(){
    shopifyBuy(this.props, this);
    

  return( 
    <>
      <div id="products-loading" className="hidden small-12 text-center" style={{marginTop: "2rem", marginBottom: "2rem"}}>
        <img src="/assets/images/loading-emblem.gif" alt="Loading Gif" style={{maxWidth: "4rem"}} />
        <p className="">Fetching products...</p>
      </div>
      <div id={'shopify-products-collection-inner-div'} className="shopify-div" ></div>
    </>    
    )
  }
}


export default shopifyEmbed;

const shopifyBuy = (props, inheritedThis) => {

if (typeof document === `undefined`) {
  return null;
} else{
  if(props.hideOthers){
    var iframes = document.getElementsByTagName("iframe");

    for (let i = 0; i< iframes.length; i++){
      let currentIframe = iframes[i].name;
      
      if(currentIframe.split("frame-productSet").length > 1 && (parseInt(currentIframe.split("frame-productSet-")[1]) !== props.collectionID)){
        // get element by name and hide...
        document.getElementsByName(currentIframe)[0].classList.add("hidden");

      } else {
          if(document.getElementsByName(currentIframe)[0]){document.getElementsByName(currentIframe)[0].classList.remove("hidden");}
      }  
    }// loop iframes to see if they contain "frame-productSet" in their name...


  }
  /*<![CDATA[*/
    (function () {

      if (typeof window !== `undefined` && props.collectionID){ // This if statement allows the build process to work w/o breaking
        var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
        let newStoreButton;

        if (window.ShopifyBuy) {
          if (window.ShopifyBuy.UI) {
            var iframeWindowName = "frame-productSet-"+props.collectionID; // shopify gives each embedded iFrame this name
            var thisIframe = document.getElementsByName(iframeWindowName);

            if (thisIframe.length === 0){
                  ShopifyBuyInit();
                } else {return null}
          } else {
            loadScript();
          }
        } else {
          loadScript();
        }
      }
      function loadScript() {
        var script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = ShopifyBuyInit;
      }
      function ShopifyBuyInit() {
        var ShopifyBuy = window.ShopifyBuy;
        var client = ShopifyBuy.buildClient({
          domain: 'emblem-athletic.myshopify.com',
          storefrontAccessToken: 'd8fb56fc81f8109e60ea3cc01a401bdc',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('collection', {
            id: props.collectionID,
            node: document.getElementById('shopify-products-collection-inner-div'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
              "product": {
                buttonDestination: 'modal',
                isButton: true,

                "styles": {
                  "product": {
                    "max-width": "calc(50% - 20px)",
                    "margin-top": "15px",
                    "min-width": "100px",
                    "@media (min-width: 601px)": {
                      "margin-top": "0px !important",
                      "max-width": "calc(20% - 20px)",
                      "margin-left": "20px",
                      "margin-bottom": "50px",
                      "width": "calc(20% - 20px)"
                    },
                    "img": {
                      "width": "1200px",
                    }
                  },
                  "button": {
                    "font-size": "16px",
                    "padding-top": "16px",
                    "padding-bottom": "16px"
                  },
                  "quantityInput": {
                    "font-size": "16px",
                    "padding-top": "16px",
                    "padding-bottom": "16px"
                  }
                },
                "text": {
                  "button": "See details"
                },
                "contents": {
                  "variantTitle": true,
                  "description": false,
                  "buttonWithQuantity": false,
                  "button": false,
                  "quantity": false,
                  "options" :true,
                },
                "order": [
                  "title",
                  "img",
                  "price",
                  "button",
                ],
                templates: {
                  img: `
                  {{#data.currentImage.srcLarge}}
                    <div class="{{data.classes.product.imgWrapper}}" data-element="product.imgWrapper">
                      <img alt="{{data.currentImage.altText}}" data-element="product.img" class="{{data.classes.product.img}}" src="{{data.currentImage.srcLarge}}" />
                    </div>
                  {{/data.currentImage.srcLarge}}
                  `,                 
                },
              },
              "productSet": {

                "styles": {
                  "products": {
                    "@media (min-width: 601px)": {
                      "margin-left": "-20px"
                    }
                  }
                },

                "events": {
                  'beforeInit': function(e){
                    const loadingDiv = document.getElementById('products-loading').classList.remove("hidden");
                  },
                  afterRender : function(e){
                    const loadingDiv = document.getElementById('products-loading').classList.add("hidden"); // hide div notifying customers that we're getting their products

                  }
                }


              },
              "modalProduct": {
                "contents": {
                  "img": false,
                  "imgWithCarousel": true,
                  "button": false,
                  "buttonWithQuantity": true,
                  "options": true,
                },
                templates: {
                  img: '{{#data.currentImage.srcOriginal}}<div class="{{data.classes.product.imgWrapper}}" data-element="product.imgWrapper"><img alt="{{data.currentImage.altText}}" data-element="product.img" class="{{data.classes.product.img}}" src="{{data.currentImage.srcOriginal}}" /></div>{{/data.currentImage.srcOriginal}}',
                  imgWithCarousel: `<div class="{{data.classes.product.imgWrapper}}" data-element="product.imageWrapper">
                                      <div class="main-image-wrapper">
                                        <button type="button" class="carousel-button carousel-button--previous">
                                          Left
                                          <img class="carousel-button-arrow" src="//sdks.shopifycdn.com/buy-button/latest/arrow.svg" alt="Carousel Arrow"/>
                                        </button>
                                        <img class="{{data.classes.product.img}}" alt="{{data.currentImage.altText}}" src="{{data.currentImage.srcOriginal}}" data-element="product.img" />
                                        <button type="button" class="carousel-button carousel-button--next">
                                          Right
                                          <img class="carousel-button-arrow" src="//sdks.shopifycdn.com/buy-button/latest/arrow.svg" alt="Carousel Arrow"/>
                                        </button>
                                      </div>
                                      <div class="{{data.classes.product.carousel}}">
                                        {{#data.carouselImages}}
                                        <a data-element="product.carouselitem" aria-label="{{altText}}" href="{{src}}" class="{{data.classes.product.carouselItem}} {{#isSelected}} {{data.classes.product.carouselItemSelected}} {{/isSelected}}" data-image-id="{{id}}" style="background-image: url({{carouselSrc}})"></a>
                                        {{/data.carouselImages}}
                                      </div>
                                    </div>`,
                  title: '<h1 class="{{data.classes.product.title}}" data-element="product.title">{{data.title}}</h1>',
                  variantTitle: '{{#data.hasVariants}}<h2 class="{{data.classes.product.variantTitle}}" data-element="product.variantTitle">{{data.selectedVariant.title}}</h2>{{/data.hasVariants}}',
                  options: '{{#data.hasVariants}}<div class="{{data.classes.product.options}}" data-element="product.options">{{{data.optionsHtml}}}</div>{{/data.hasVariants}}',
                },


                "styles": {
                  "product": {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0px",
                      "margin-bottom": "0px"
                    },
                  },
                  "button": {
                    "font-size": "16px",
                    "padding-top": "16px",
                    "padding-bottom": "16px"
                  },
                  "quantityInput": {
                    "font-size": "16px",
                    "padding-top": "16px",
                    "padding-bottom": "16px"
                  }
                }
              },
              "cart": {
                "styles": {
                  "button": {
                    "font-size": "16px",
                    "padding-top": "16px",
                    "padding-bottom": "16px"
                  },
                  "title": {
                    "color": "#4c4c4c"
                  },
                  "header": {
                    "color": "#4c4c4c"
                  },
                  "lineItems": {
                    "color": "#4c4c4c"
                  },
                  "subtotalText": {
                    "color": "#4c4c4c"
                  },
                  "subtotal": {
                    "color": "#4c4c4c"
                  },
                  "notice": {
                    "color": "#4c4c4c"
                  },
                  "currency": {
                    "color": "#4c4c4c"
                  },
                  "close": {
                    "color": "#4c4c4c",
                    ":hover": {
                      "color": "#4c4c4c"
                    }
                  },
                  "empty": {
                    "color": "#4c4c4c"
                  },
                  "noteDescription": {
                    "color": "#4c4c4c"
                  },
                  "discountText": {
                    "color": "#4c4c4c"
                  },
                  "discountIcon": {
                    "fill": "#4c4c4c"
                  },
                  "discountAmount": {
                    "color": "#4c4c4c"
                  }
                }
              },
              "toggle": {
                "styles": {
                  "count": {
                    "font-size": "16px"
                  }
                }
              },
              "lineItem": {
                "styles": {
                  "variantTitle": {
                    "color": "#4c4c4c"
                  },
                  "title": {
                    "color": "#4c4c4c"
                  },
                  "price": {
                    "color": "#4c4c4c"
                  },
                  "fullPrice": {
                    "color": "#4c4c4c"
                  },
                  "discount": {
                    "color": "#4c4c4c"
                  },
                  "discountIcon": {
                    "fill": "#4c4c4c"
                  },
                  "quantity": {
                    "color": "#4c4c4c"
                  },
                  "quantityIncrement": {
                    "color": "#4c4c4c",
                    "border-color": "#4c4c4c"
                  },
                  "quantityDecrement": {
                    "color": "#4c4c4c",
                    "border-color": "#4c4c4c"
                  },
                  "quantityInput": {
                    "color": "#4c4c4c",
                    "border-color": "#4c4c4c"
                  }
                }
              }
            },
          });
        });
      }
    })()
    /*]]>*/
  } 
}




